.login-content {
   background-color: @white-grey;
   > div {
      padding: 35px 40px;
      background-color: @white;
   }
   .ant-form-item-label, .ant-form-item-control  {
      width: 100%;
   }

}
.filterSticky{
   position: sticky;
   top: 0px;
   display: block;
   z-index: 999;
   padding: 10px 0;
   background-color: white;
}
