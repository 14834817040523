@import "./font.less";
@import "./login.less";
@import "../../../node_modules/antd/dist/antd.less";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
body {
  font-family: "Source Sans Pro", sans-serif;
  scroll-behavior: smooth;
}


/*
  StyleSheet Documentation
  1. Declaring Variable
    1.1   Base Scaffolding Variables
    1.2   Colors
    1.3   Border Color
    1.4   Form
    1.5   Input
    1.6   Select
    1.7   Checkbox
    1.8   Radio
    1.9   Buttons
    1.10  PageHeader
    1.11  Collapse
    1.12  Card
    1.13  Layout
    1.14  Global
  2. Components
    2.1   Login
  3. Antd
    3.1   Layout
    3.2   Input
    3.3   Button
    3.4   Checkbox
    3.5   Select
    3.6   Card
    3.7   Collapse
  4. UI
    4.1   Card
    4.1.1 Card Choose
  5. Responsive
    5.1   Home
    5.2   Ads
*/

// 1. Declaring Variable
// 1.1 Base Scaffolding Variables
@heading-color: #262626;
@font-size-base: 16px;
@text-color: @heading-color;
@border-radius-base: 4px;
@font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Lato,
  "Helvetica Neue", Arial, Gotham-Ultra, Gotham-Bold, Gotham-Medium, Gotham-Book,
  "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji";

// 1.2 Colors
@white: #ffffff;
@white-grey: #eeeded;
@black: #000000;
@grey: #b4b3b3;
@red: #f73f3f;
@grey-dark: #5f5f5f;
@primary-color: #338fde;
@primary-color-hover: #2977bb;

// 1.3 Border color
@border-color-base: @primary-color;

// 1.4 Form
@form-item-margin-bottom: 12px;
@form-vertical-label-padding: 0 0 4px;

// 1.5 Input
@input-height-base: 50px;
@input-color: @black;
@box-shadow-color: #ffe0e1;
@input-placeholder-color: color(~`colorPalette("@{grey}", 4) `);
@input-border-color: color(~`colorPalette("@{grey}", 4) `);

// 1.6 Select
@select-item-selected-bg: @grey;
@select-item-active-bg: @grey;
@select-item-hover-bg: color(~`colorPalette("@{grey}", 4) `);
@select-border-color: color(~`colorPalette("@{primary-color}", 4) `);

// 1.7 Checkbox
@checkbox-size: @font-size-base + 4;

// 1.8 Radio
@radio-size: @font-size-base + 4;

// 1.9 Buttons
@btn-disable-color: @white;
@btn-disable-bg: color(~`colorPalette("@{primary-color}", 4) `);
@btn-disable-border: color(~`colorPalette("@{primary-color}", 4) `);
@btn-height-base: @input-height-base;

// 1.10 PageHeader
@page-header-padding: 0;
@page-header-padding-vertical: 10px;

// 1.11 Collapse
@collapse-header-padding: 12px 0;
@collapse-header-padding-extra: 0;
@collapse-header-bg: @white;
@collapse-content-bg: @white;

// 1.12 Card
@card-border-radius: 40px;
@card-border-color: #b4b3b3;

// 1.13 Layout
@layout-base: 480px;
@layout-header-background: #f3f3f3;
@layout-body-background: @layout-header-background;
@layout-header-height: 56px;
@layout-content: 0 18px;
@layout-header-padding: 0;
@layout-footer-padding: 0;

// 1.14 Global
html,
body {
  background-color: @layout-body-background;
  --primary-color: @primary-color;
  --input-color: @input-color;
  --primary-color-hover: @primary-color-hover;
  --box-shadow-color: @box-shadow-color;
  --cart-box-color: @box-shadow-color;
  font-family: "Gotham-Book";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: var(--primary-color);
}
h5.ant-typography{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}
.text-important {
 color: var(--primary-color);
}

.capitalize {
  text-transform: capitalize;
}

.center-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
  display: block;
}

.desc {
  color: @grey-dark;
}
.width-cover {
  width: 80%;
}

.h-100 {
  height: 100vh;
}

.w-100 {
  width: 100%;
}

.action {
  cursor: pointer;
}

.ant-form-item-label {
  label {
    font-size: @font-size-base - 2;
  }
}
.ant-form-item-explain {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  font-size: @font-size-base - 4;
}
.ant-table-thead > tr > th{
  text-align: center;
}
.ant-btn.ant-input-search-button,
.ant-picker-range,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker,
.ant-picker.ant-picker-disabled,
.ant-radio-inner,
.ant-input-number,
.ant-input {
  border-color: color(~`colorPalette("@{grey}", 3) `);
}
.ant-input:disabled,
.ant-input:disabled::placeholder {
  color: #3b3b3b;
  font-weight: bold;
}
.sm-text {
  font-size: @font-size-base - 4;
}

// 2. Components
// 2.1 Login
.ant-layout {
  .ant-layout-sider-collapsed {
    .logo {
      background: url("../../resources/img/logo-small.png");
      color: #f3f3f3;
      height: 32px;
      margin: 15px;
      background-size: cover;
    }
  }
  .logo {
    background: url("../../resources/img/wispay-logo.png");
    color: #f3f3f3;
    height: 27px;
    margin: 20px 30px;
    background-size: cover;
  }
}
.disableFilter {
  color: #b4b3b3;
}
.add-denom {
  .ant-row {
    margin-right: 15px;
  }
}

// 3. Antd
// 3.1  Layout
.ant-menu-dark.ant-menu-submenu-popup {
  .ant-menu-sub {
    background-color: var(--primary-color);
    .ant-menu-item-active {
      background-color: var(--primary-color-hover);
    }
  }
  .ant-menu-item-only-child.ant-menu-item-selected {
    background-color: var(--primary-color-hover);
  }
}

.ant-layout {
  &.splashscreen {
    height: 100vh;
  }
  .ant-content {
    background-color: @white;
  }
  .ant-layout-header,
  .site-layout-background,
  .site-content {
    background-color: @white;
    position: relative;
  }
  .site-header {
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
  }
  .site-main {
    display: flex;
    flex-direction: column;
  }
  .site-content {
    display: flex;
    flex: 1;
  }
  .ant-layout-footer {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 11px;
  }
  .ant-menu-submenu-selected {
    background-color: var(--primary-color-hover);
  }
  .ant-layout-sider {
    .ant-layout-sider-children {
      background-color: var(--primary-color);
      position: fixed;
      width: 200px;
      overflow-y: auto;
      overflow-x: hidden;

      .ant-menu {
        background-color: var(--primary-color);

        .ant-menu-item a,
        .ant-menu-submenu-title .anticon + span {
          font-size: @font-size-base - 2;
        }
        &.ant-menu-inline {
          li {
            margin-bottom: 0;
            margin-top: 0;
            .ant-menu-submenu-title {
              line-height: 48px;
              margin-top: 0;
            }
          }
        }
        .ant-menu-submenu-vertical.ant-menu-submenu-open,
        .ant-menu-item:hover {
          background-color: var(--primary-color-hover);
          .anticon {
            color: @white;
          }
        }
        .ant-menu-item-selected {
          background-color: var(--primary-color-hover);
        }
      }
    }
  }
  .ant-layout-sider-trigger {
    background-color: var(--primary-color-hover);
  }
  .ant-pagination-disabled:hover button {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .ant-pagination-item {
    border-color: rgba(0, 0, 0, 0.25);
    &:hover,
    &.ant-pagination-item-active {
      border-color: @primary-color;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border: 1px solid rgba(0, 0, 0, 0.25);
    }
  }
  .ant-pagination-options {
    .ant-pagination-options-size-changer {
      display: none;
    }
  }
}

// PopOver

.ant-popover {
  &.ant-popover-placement-bottomRight ul {
    border-right: 0;
    li {
      line-height: 28px;
      padding-left: 10px;
      padding-right: 10px;
      height: auto;
      a {
        font-size: @font-size-base - 3;
      }
    }
  }
  .ant-popover-inner-content {
    h5 {
      line-height: 24px;
      cursor: pointer;
      &:hover {
        color: @primary-color;
      }
    }
  }
}

//Tabel
.ant-layout {
  .ant-table-cell {
    font-size: @font-size-base - 3;
    letter-spacing: 0.4px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.small-form {
  width:98%;
  input,
  .ant-select,
  textarea {
    font-size: @font-size-base - 4;
  }
  .ant-input-search-button,
  .ant-input-number,
  .ant-input-number-input,
  .ant-select:not(.ant-select-multiple) .ant-select-selector {
    height: 44px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 44px;
  }
}
.ant-select-item-option-active,
.ant-select-item-option-selected {
  background-color: #e8e8e8 !important;
}

.ant-tabs-left {
  .ant-tabs-nav {
    padding-top: 15px;
  }
  .ant-tabs-content-holder {
    padding-top: 15px;
    padding-right: 20px;
  }
  .ant-tabs-tab-btn {
    font-size: @font-size-base - 2;
  }
  .ant-tabs-tab {
    margin-bottom: 10px !important;
  }
}
.ant-select-multiple .ant-select-selection-search {
  margin-top: 0;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: @white;
  cursor: pointer;
  color: @black;
}
.ant-modal-footer button {
  min-width: 100px;
}
.ant-btn-sm {
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  &:not(.ant-btn-primary) {
    color: @primary-color;
  }
}

.ant-btn {
  &:not(.ant-btn-primary) {
    color: @primary-color;
  }
  &[disabled] {
    &:not(.ant-btn-primary) {
      background-color: @white;
      opacity: 0.4;
      &:hover {
        color: @primary-color;
      }
    }
  }
}

.ant-input-affix-wrapper {
  padding: 8.4px 11px;
}
.ant-form-item {
  margin-bottom: 8px;
}
.ant-form-item-label {
  padding-bottom: 0px !important;
}
.ant-form-item-explain {
  margin-top: 0;
}
.ant-form-item-control-input {
  min-height: 40px;
}
.ant-picker,
.ant-input-number {
  width: 100%;
}
.ant-tooltip-inner {
  font-size: @font-size-base - 5;
}

.ant-modal-wrap .popup-gallery.ant-modal{
  width: 500px!important;
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
.__json-pretty__{
  ooverflow: scroll!important;
  max-height: 180px;
  min-height: 50px;
}
.__json-pretty-error__{
  ooverflow: scroll!important;
  max-height: 180px;
  min-height: 50px;
  padding: 20px;
}


.title-code {
  width: 100%;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  margin: 0;
}
.promo-title {
  width: 100%;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.promo-code {
  background-color: color(~`colorPalette("@{primary-color}", 2) `);
  padding: 2px 10px;
  border-radius: 8px;
  font-size: @font-size-base - 6;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.total-promo {
  font-style: italic;
  font-size: @font-size-base - 5;
}

.italic {
  font-style: italic;
}

.action-divider {
  height: 20px;
  border-color: @grey;
}

.outline-popover {
  background-color: @primary-color;
  padding: 5px;
  border-radius: 100px;
  cursor: pointer;
  color: @white;
}
.trash {
  width: 50px;
  margin-top: 10px;
  color: @red;
  cursor: pointer;
}
.ant-menu-submenu-arrow {
  top: 60%;
}
.ant-table-content .ant-table-cell {
  input {
    width: 80px;
    font-size: @font-size-base - 2;
    padding: 5px 10px;
  }
  .ant-picker {
    width: 150px;
    font-size: @font-size-base - 2;
    padding: 2px 10px;
  }
}
.tox-notification.tox-notification--warning {
  display: none !important;
}
.ant-upload-list-item-thumbnail,
.ant-upload-list-item-thumbnail .ant-upload-list-item-image {
  height: auto !important;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  border-color: unset !important;
}
.ant-select-multiple .ant-select-selection-item-content {
  color: @black!important;
}

.jam-operasi {
  .ant-picker {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid @grey;
  }
}

.accept-payment {

}
.swiper-button-next, .swiper-button-prev, .swiper-pagination-bullet-active{
color: white!important;
}
.swiper-pagination-bullet-active{
background-color: white!important;
}
.box-bottom {
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  padding: 20px;
  width: calc(100% - 232px);
  z-index: 99;
  margin-left: -24px;
  -webkit-box-shadow: 1px -3px 5px #e8e8e8;
  -moz-box-shadow: 1px -3px 5px #e8e8e8;
  box-shadow: 1px -3px 5px #e8e8e8;
  align-items: center;

  .box-bottom-cover {
    display: flex;
    align-items: center;
    .box-bottom-left {
      display: flex;
      flex: 1;
    }
    .box-bottom-info {
      float: right;
      button {
        margin-left: 15px;
      }
    }
  }
}

.upload-file-full {
  .ant-upload-select {
    width: 100%;
    min-height: 118px;
    height: 104px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #0a4df5;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  .ant-upload > .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
}
.boxed-border {
  padding: 20px;
  border: 1px solid @input-border-color;
  border-radius: 10px;
}

.notification {
  max-width: 400px;
  .cover {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .border {
    border-bottom: 1px solid @input-border-color;
  }
  .ant-typography.title {
    font-size: @font-size-base - 3;
  }
  .ant-typography.date {
    font-size: @font-size-base - 6;
    color: @grey-dark;
  }
}
// 5. Responsive
// 5.1  Global
.role {
  background-color: color(~`colorPalette("@{primary-color}", 5) `);
  color: #fafafa;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: @font-size-base - 4;
}

.btn-status {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  font-size: 12px;
}

.btn-purchasable {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a4df5;
  color: white;
  width: 100%;
  padding: 10px 0;
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;

  h5 {
    font-weight: bold;
    font-size: 10px;
    color: white;
    margin: 0;
  }
}

.decline {
  background: rgba(255, 0, 0, 0.2);
  border-radius: 29px;
  color: #db0112;
}

.accept {
  background: rgba(78, 177, 37, 0.2);
  border-radius: 29px;
  color: #4eb125;
}

.input-addon {
  .ant-input-group-addon {
    font-size: 12px;
  }
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: 160px;
  top: -7px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
  left: 39%;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  left: 39%;
}

.ant-timeline.ant-timeline-label
  .ant-timeline-item-left
  .ant-timeline-item-content {
  width: 250px;
  left: calc(50% - 50px);
}

.timeline-tracking {
  padding-top: 15px;

  .ant-timeline-item-label {
    font-size: 14px;
    color: rgb(112, 112, 112);
  }
}

.category-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  border-radius: 100px;
  background: rgba(0, 0, 255, 0.1);
  color: blue;
  font-size: 12px;
  font-weight: bold;
}

.search-store {
  .ant-input-affix-wrapper.ant-input-affix-wrapper-sm {
    border-top: 1px solid transparent;
    border-bottom: 1px solid #cfc3c2;
    border-radius: 0;
    padding: 8.4px 11px 8.4px 0;
  }

  .ant-input-search-button {
    height: 44px;
    width: 44px;
  }
}

.editable-text {
  .ant-input {
    width: 100%!important;
  }
}

.section-store-merchant {
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 24px;
  gap: 16px;

  .avatar-store {
    max-width: 70px;
    width: 100%;
    height: 70px;
    object-fit: contain;
    object-position: center;
    border-radius: 100px;
  }

  .store-name {
    font-size: 18px;
    font-weight: bolder;
  }

  .store-info {
    gap: 6px;
    align-items: center;

    img {
      max-width: 15px;
      width: 100%;
      height: 15px;
    }

    span {
      font-size: 14px;
    }
  }
}

.foto-produk-scrape {
  gap: 5px;

  .ant-image-img {
    max-width: 40px;
    width: 100%;
    height: 40px;
    object-fit: contain;
    object-position: center;
    border-radius: 4px;
    border: 1px solid #b4b3b3;
  }

  .video-product-scrape {
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #b4b3b3;
    position: relative;

    span {
      position: absolute;
      background-color: white;
      border-radius: 100px;
      z-index: 100;
    }

    .ant-image-img {
      max-width: 40px;
      width: 100%;
      height: 40px;
      object-fit: contain;
      object-position: center;
      border-radius: 4px;
      border: 1px solid #b4b3b3;
    }
  }
}

.ant-table-tbody .ant-table-cell{
  text-align: center;
}
.copy-clipboard{
  font-size: 13px;
  float: right;
  margin-bottom: 10px;
  background-color:white;
  color:@primary-color;
  border: 1px solid@primary-color;
}
.title-payload{
  fontSize: 14px;
  color:@primary-color;
  font-weight: 600;
}

h6 {
  font-size: 15px;
  font-weight: 900;
}
.icon-delete{
  margin-left: 5px;
  width: 20px;
  height: 20px;
}
.anticon-delete{
  font-size: 20px;
  color:#9F9F9F;

}
.border-button{
 border: 1px solid @primary-color;
  border-radius: 4px;
  background: linear-gradient(228.07deg, rgba(255, 186, 224, 0.05) 6.75%, rgba(254, 47, 162, 0.05) 78.67%);
  color: @primary-color;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

}
.border-button:hover {
  color: #ffffff;
  background: linear-gradient(101.15deg,@primary-color 3.75%, @primary-color 96.16%);
  border: none;
}
.border-button:focus {
  color: #ffffff;
  background: linear-gradient(101.15deg,@primary-color 3.75%, @primary-color 96.16%);
  border: none;
}
.title-form{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}
.cluster-div{
  margin-bottom: 20px;
  .ant-input{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333333;

  }
   .ant-input:disabled{
    color:#BBBBBB;
  }
}
.wilayah-cluster{
  .ant-form-item-label{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
  .ant-form-item-label > label {
    color: #666666;
  }
}
.primary-button{
 background: linear-gradient(101.15deg,@primary-color 3.75%, @primary-color 96.16%);
  border-radius: 4px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
  border: none;

}
.primary-button:hover {
background: linear-gradient(101.15deg, @primary-color 3.75%,@primary-color 96.16%);
  color: white;
}
.ant-btn-primary[disabled]{
  color: #ffffff;
   background: linear-gradient(101.15deg,@primary-color 3.75%,@primary-color 96.16%);
  border: none;
}
.ant-btn-primary[disabled]:hover{
  color: #ffffff;
   background: linear-gradient(101.15deg,@primary-color 3.75%,@primary-color 96.16%);
  border: none;
}
.ant-input:hover {
  border-color:@primary-color;
  border-right-width: 1px;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color:@primary-color;
  border-right-width: 1px;
}
.ant-input-group-addon {
  background-color: #EEEEEE;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #999999;
}
.icon-formedit{
  font-size: 24px;
  color: #999999;
  cursor: pointer;
}
.ant-switch-checked{
   background:@primary-color;
}
.modal-PIN{
  .ant-modal-header{
    text-align: center;
    border-bottom: none;
  }
  .ant-modal-title{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
  }
  .ant-modal-footer{
    display: flex;
    justify-content: center;
    align-items:center ;
  }
  .ant-modal-body{
    display: flex;
    justify-content: center;
    align-items:center ;
  }

}
 .ant-btn-primary:focus {
  color: #fff;
  border-color: @primary-color;
  background: linear-gradient(101.15deg, @primary-color 3.75%, @primary-color 96.16%);
}
.ant-input-affix-wrapper-focused{
  border-color:@primary-color;
  box-shadow: rgba(135, 2, 76, 0.16) 0px 10px 36px 0px, rgba(126, 1, 53, 0.06) 0px 0px 0px 1px;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
border-color:@primary-color;
  border-right-width: 1px;
  z-index: 1;
}
.detail-selles{
  width: 100%;
  .salles-propict{
    width: 37px;
    height: 37px;
    margin-right: 20px;
  }
  .salles-name{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #333333;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .ant-col-10{
    padding: 0 20px;
  }
  .ant-form-item-label{
    width: 100%;
    text-align: left;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #666666
  }
  .ant-input{
    border-radius: 10px;
  }
  .ant-input-number-input{
    border-radius: 10px;
  }
}
.edit-cluster{
  cursor: pointer;
  svg{
    height: 19px;
    width: 19px;
    color: #9F9F9F;
    transition: color 0.5s ease-out 100ms;
  }
  :hover{
    color: #EE4C24;
  }
}
.new-product{
  padding: 2px 4px;
  margin-left: 10px;
  width: 32px;
  height: 19px;
  background: #DA473F;
  border-radius: 6px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}
.is_digipos{
  padding: 0px 8px;
  float: right;
  background-color: @primary-color;
  font-size: 10px;
  border-radius: 10px;
  color: #fff;
}
.is_digipos:after{
  content: "Active";
}
.ant-select-selection-item-content{
  .is_digipos{display: none;}
}
.spin-loading{
  display: flex;
  text-align: center;
  height: 22px;
  align-items: center;
  justify-content: center;

}
.ant-table-cell .toogle svg{
  font-size: 14px!important;
  width: 24px;
  height: auto;
  color: #0a4df5;
}
.ant-table-cell-row-hover  .toogle svg:hover{
  width: 24px;
  height: auto;
  color: #0a4df5;
}
.edit-cluster :hover {
  color: #0a4df5;
}
.ant-table-cell .checkbox svg{
  font-size: 14px!important;
  width: 20px;
  height: auto;
  color: #0a4df5;
}
.cluster-select {
  .ant-select-item-option-state{
    padding: 0px 8px;
    float: right;
    background-color: #4eb125;
    font-size: 10px;
    border-radius: 10px;
    color: #fff!important;
    margin-left: 5px;
  }
}
.ant-select-item-option-selected{
  .is_digipos{
    padding: 0px 8px;
    float: right;
    background-color: #db0112;
    font-size: 10px;
    border-radius: 10px;
    color: #fff;
  }
  .is_digipos:after{
    content: "Take";
  }
}
.box{
  color: #333333;
  font-size: 10px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  margin-left: 10px;
  .biru{
    width: 10px;
    height: 10px;
    background-color: #0a4df5;
    float: left;
    margin-right: 5px;
  }
  .hijau{
    width: 10px;
    height: 10px;
    background-color: #4eb125;
    float: left;
    margin-right: 5px;
  }
  .merah{
    width: 10px;
    height: 10px;
    background-color: #db0112;
    float: left;
    margin-right: 5px;
  }
}
.leaderboard{
  .ant-table-tbody > tr > td {
    border-bottom: 0px solid #a80606;
    // transition: background 0.3s;
  }
  .ant-table-tbody{

    background-color: #DAC7FF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

  }
  .ant-table-tbody > tr > td{
    padding: 0 0 0 0 !important;
  }
  .ant-table-placeholder{
    width: 100%;
  }
  p {
    margin-top: 0!important;
    margin-bottom: 0!important;
  }
  .row-leaderboard{
    border-radius: 10px;
    margin: 0!important;
    box-shadow: #76174b 0px 3px 0px;
  }
  .circular--portrait {
    position: relative;
    width: 55px;
    height: 55px;
    overflow: hidden;
    border-radius: 50%;
 }
  .circular--portrait img { width:auto; height: 55px; }
  .point{
    background: linear-gradient(90deg,#925EFFBD 0%, #925ADD 100%);
    border-radius: 10px;
    color: #ffffff;
    font-weight: 600;
    padding: 0 5px;
    font-size: 20px;
    width: fit-content;
    padding: 0 10px;
    margin: auto 0;
  }
  .ant-table-tbody{
    display: block;
    columns: 2 auto;
    flex-flow: row wrap;
    min-width: 0;
  }
  .ant-table-row{
    --ant-display: block;
    display: block;
    padding:  0px 20px 20px 20px;

  }
  .ant-table-cell{
    display: block;
  }
  .ant-table-placeholder {
    position: absolute;
  }

}
.gamePortrait{
  // position: relative;
  width: 20px;
  height: 20px;
  // overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  margin: auto 0;
}


.ant-table-row:has(.dummy_data) {
  display: none;
}
.top_three_leaderboard{
  background-color: #DAC7FF;
  width: 100%;
  padding-bottom: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  .inside-background{
    padding: 20px;
    background-image:
      url("../../resources/img/Pattern.png"), linear-gradient(125deg,
      #8462AF,#4F3997
      );
    background-repeat: repeat;
    background-size: contain;
    border-radius: 20px;
    // border-bottom-right-radius: 20px;
    box-shadow: #6941D540 0px 10px,  ;
  }
  .border-outside-first{
    border: 20px solid #ffffff3b ;
    border-radius: 50%;
    margin: auto;
    position: relative;
    width:240px;
    height:240px;
  }
  .border-first{
    border: 5px solid #fff ;
    border-radius: 50%;
    margin: auto;
    position: relative;
    width:200px;
    height:200px;
    background-color:  #fff;
  }
  .border-inside-first{
    border: 10px solid #EE4C24 ;
    border-radius: 50%;
    margin: auto;
    position: relative;
    width:190px;
    height:190px;
    background-color:#EE4C24;
  }
  .circular--portrait-first {
    margin: auto;
    position: relative;
    width:170px;
    height:170px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 10px;
    // border: 10px solid #EE4C24;
 }

  .circular--portrait-first img { width:auto; height:170px; }
  .champions{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    img{
      height:100px;
    }
  }
  .crown{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 20px;
    img{
      height:100px;
    }
  }
  .number{
    margin: auto;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    text-align: right;
    color: #fff;
    text-align: center;

  }
  .left{
    position: absolute;
    top: 250px;
    left: 17%;
  }
  .right{
    position: absolute;
    top: 250px;
    right: 17%;
  }
  .border-outside{
    border: 20px solid #ffffff3b ;
    border-radius: 50%;
    margin: auto;
    position: relative;
    width:220px;
    height:220px;
  }
  .border{
    border: 5px solid #fff ;
    border-radius: 50%;
    margin: auto;
    position: relative;
    width:180px;
    height:180px;
    background-color:  #fff;
  }
  .border-inside{
    border: 10px solid #EE4C24 ;
    border-radius: 50%;
    margin: auto;
    position: relative;
    width:170px;
    height:170px;
    background-color:#EE4C24;
  }
  .circular--portrait {
    margin: auto;
    position: relative;
    width:150px;
    height:150px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 10px;
    // border: 5px solid #EE4C24;
 }
  .circular--portrait img { width:auto; height:150px; }
  .center{
    margin: auto;
    text-align: center;
  }
  .title-first{
    font-family: Source Sans Pro;
    font-size: 40px;
    font-weight: 600;
    line-height: 24.77px;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .title{
    font-family: Source Sans Pro;
    font-size: 34px;
    font-weight: 600;
    line-height: 24.77px;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .point{
    padding: 5px 10px;
    background: linear-gradient(90deg,#F68121 100%, #FCAE18 100%);
    width: fit-content;
    margin: auto;
    border-radius: 10px;
    color: #fff;
    font-weight: 600;
    font-size: 26px;

    .dot{
      display: flex;
      float: left;
      margin-right: 10px;
      height: 16px;
      width: 16px;
      background-color: #FCD302;
      border-radius: 50%;
      margin-top:11px;
    }
  }
}
.ant-input:disabled, .ant-input:disabled::placeholder {
  color: #bebebe;
  font-weight: 400;
}